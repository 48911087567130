<!-- 政策类型管理 -->
<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right div40">
      <el-breadcrumb-item><a>首页</a></el-breadcrumb-item>
      <el-breadcrumb-item>申报管理</el-breadcrumb-item>
      <el-breadcrumb-item>类型管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <!-- <el-button
         
          @click="back()"
          icon="el-icon-caret-left"
          size="small"
          title="返回上一级"
        ></el-button> -->

        <el-button
          class="fl"
          @click="AddDialog = true"
          icon="el-icon-plus"
          type="text"
          title="新增类型"
          >新增类型</el-button
        >
        <el-button
          class="fl"
          icon="el-icon-refresh"
          type="text"
          @click="geTtypeData(page, pageSize)"
          >刷新</el-button
        >

        <div class="fr">
          <el-input
            prefix-icon="el-icon-search"
            placeholder="请输入关键字"
            v-model="searchvalue"
            clearable
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="peopleSearch"
            ></el-button>
          </el-input>
        </div>
      </div>
      <el-table :data="typeData" style="width: 100%">
        <!-- <el-table-column prop="id" label="id"> </el-table-column> -->
        <el-table-column label="类型名称">
          <template slot-scope="scope">
            <el-tag>{{ scope.row.name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="frequency" label="次数"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-edit"
              plain
              @click="shosEditClick(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="hr-10"></div>
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="requestPage"
      >
      </el-pagination>
    </el-card>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="editDialog" width="600px" center>
      <el-row :gutter="20">
        <el-col :span="4"
          ><div class="grid-content">
            类型名称
          </div></el-col
        >
        <el-col :span="20"
          ><div class="grid-content">
            <el-input
              v-model="editData.name"
              placeholder="请输入内容"
            ></el-input></div
        ></el-col>
      </el-row>
      <div class="hr-10"></div>
      <el-row :gutter="20">
        <el-col :span="4"
          ><div class="grid-content">
            次数
          </div></el-col
        >
        <el-col :span="20"
          ><div class="grid-content">
            <el-input
              type="number"
              v-model="editData.frequency"
              placeholder="请输入次数"
            ></el-input></div
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="CancelupdateClick">取 消</el-button>
        <el-button size="small" type="primary" @click="updateClick"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <!-- 新增 -->
    <el-dialog title="新增类型" :visible.sync="AddDialog" width="600px" center>
      <el-row :gutter="20">
        <el-col :span="4"
          ><div class="grid-content">
            类型名称
          </div></el-col
        >
        <el-col :span="20"
          ><div class="grid-content">
            <el-input
              v-model="AddData.name"
              placeholder="请输入内容"
            ></el-input></div
        ></el-col>
      </el-row>
      <div class="hr-10"></div>
      <el-row :gutter="20">
        <el-col :span="4"
          ><div class="grid-content">
            次数
          </div></el-col
        >
        <el-col :span="20"
          ><div class="grid-content">
            <el-input
              type="number"
              v-model="AddData.frequency"
              placeholder="请输入次数"
            ></el-input></div
        ></el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="CancelAddtype">取 消</el-button>
        <el-button size="small" type="primary" @click="addtype"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import configDate from "../../../utils/config";
export default {
  data() {
    return {
      typeData: [],
      total: 0, //总条数
      page: 1, //当前页数
      pages: 0, //页数
      pageSize: 5, //每页多少条
      editDialog: false, //控制编辑框显示
      editData: {},
      AddDialog: false, //控制新增框显示
      AddData: {
        frequency: 0,
        name: "",
      },
      searchvalue: "",
    };
  },
  created() {
    this.geTtypeData(1, this.pageSize);
  },
  methods: {
    //返回上一级
    back() {
      this.$router.back();
    },
    //获取类型列表
    geTtypeData(page, Size) {
      const self = this;
      this.$axios
        .get("/affairType/selectAll", {
          params: {
            pageNo: page,
            pageSize: Size,
          },
        })
        .then(function(res) {
          if (res.data.status === 200) {
            self.typeData = res.data.data.records;
            self.total = res.data.data.total; //总条数
            self.pages = res.data.data.pages; //页数
          }
        });
    },
    //新增
    addtype() {
      const self = this;
      this.$axios.post("/affairType/save", this.AddData).then(function(res) {
        if (res.data.status === 200) {
          self.CancelAddtype();
          self.geTtypeData(1, this.pageSize);
        }
      });
    },
    //取消新增
    CancelAddtype() {
      this.AddDialog = !this.AddDialog;
      this.AddData = {
        frequency: 0,
        name: "",
      };
    },
    //打开编辑
    shosEditClick(row) {
      this.editDialog = !this.editDialog;
      this.editData = row;
    },
    //修改
    updateClick() {
      const self = this;
      this.$axios.put("/affairType/update", this.editData).then(function(res) {
        if (res.data.status === 200) {
          self.geTtypeData(self.page, self.pageSize);
          self.editDialog = !self.editDialog;
        }
      });
    },
    //取消修改
    CancelupdateClick() {
      this.editDialog = !this.editDialog;
      this.editData = {};
      this.geTtypeData(this.page, this.pageSize);
    },
    //分页
    requestPage(page) {
      this.page = page;
      this.geTtypeData(page, this.pageSize);
    },

    peopleSearch() {
      const self = this;
      this.$axios
        .get("/affairType/selectAll", {
          params: {
            pageNo: 1,
            pageSize: self.pageSize,
            name: self.searchvalue,
          },
        })
        .then(function(res) {
          console.log(res.data);
          if (res.data.status === 200) {
            self.typeData = res.data.data.records;
            self.total = res.data.data.total; //总条数
            self.pages = res.data.data.pages; //页数
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.clearfix {
  overflow: hidden;
}

/deep/ .el-pagination ul {
  padding-left: 0;
}
.bg-purple {
  background: #efefef;
}

.grid-content {
  text-align: center;
  min-height: 36px;
  line-height: 36px;
}
</style>
